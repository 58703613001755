var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v(_vm._s(this.$lang.dashboard.header.waiting_to_verified))]),_c('span',{staticStyle:{"float":"right"}},[_c('CBadge',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.count))])],1)]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.pendingProfilesTableItems,"fields":_vm.pendingProfilesTableFields,"head-color":"light","no-sorting":""},on:{"row-clicked":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{},[(item.type==='Company')?_c('Avatar',{attrs:{"image":item.avatar,"defaultImage":item.avatar,"content":item.company,"length":20}}):_c('Avatar',{attrs:{"image":item.avatar,"defaultImage":item.avatar,"content":item.name,"length":20}})],1)}},{key:"email",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{attrs:{"title":item.email}},[_vm._v(_vm._s(_vm.trimfunction(item.email, 15)))])])}},{key:"description",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{attrs:{"title":item.description}},[_vm._v(_vm._s(_vm.trimfunction(item.description, 15)))])])}}])}),(_vm.showProfile)?_c('CRow',{staticStyle:{"margin-top":"2px"}},[_c('CCol',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-light btn-block mb-1",attrs:{"title":"View All Profile"},on:{"click":_vm.viewProfiles}},[_vm._v(" View All ")])])],1):_vm._e()],1),_c('CCardHeader',[_c('strong',[_vm._v(_vm._s(this.$lang.dashboard.header.user_session_report))]),_c('span',{staticStyle:{"float":"right"}},[_c('CBadge',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.count))])],1)]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.sessionTableItems,"fields":_vm.sessionTableFields,"head-color":"light","no-sorting":""},on:{"row-clicked":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{attrs:{"title":item.name}},[_vm._v(_vm._s(_vm.trimfunction(item.name, 15)))])])}},{key:"email",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{attrs:{"title":item.email}},[_vm._v(_vm._s(_vm.trimfunction(item.email, 15)))])])}},{key:"description",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{attrs:{"title":item.description}},[_vm._v(_vm._s(_vm.trimfunction(item.description, 15)))])])}}])}),(_vm.showProfile)?_c('CRow',{staticStyle:{"margin-top":"2px"}},[_c('CCol',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-light btn-block mb-1",attrs:{"title":"View All Profile"},on:{"click":_vm.viewProfiles}},[_vm._v(" View All ")])])],1):_vm._e()],1),_c('CCardHeader',[_c('strong',[_vm._v(_vm._s(this.$lang.dashboard.header.user_activity_report))]),_c('span',{staticStyle:{"float":"right"}},[_c('CBadge',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.count))])],1)]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.activityTableItems,"fields":_vm.activityTableFields,"head-color":"light","no-sorting":""},on:{"row-clicked":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{attrs:{"title":item.name}},[_vm._v(_vm._s(_vm.trimfunction(item.name, 15)))])])}},{key:"email",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{attrs:{"title":item.email}},[_vm._v(_vm._s(_vm.trimfunction(item.email, 15)))])])}},{key:"description",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{attrs:{"title":item.description}},[_vm._v(_vm._s(_vm.trimfunction(item.description, 15)))])])}}])}),(_vm.showProfile)?_c('CRow',{staticStyle:{"margin-top":"2px"}},[_c('CCol',{staticClass:"col-12 text-right"},[_c('button',{staticClass:"btn btn-light btn-block mb-1",attrs:{"title":"View All Profile"},on:{"click":_vm.viewProfiles}},[_vm._v(" View All ")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }