<template>
  <CCard>
    <CCardHeader>
      <strong>{{ this.$lang.dashboard.header.waiting_to_verified }}</strong>
      <span style="float: right;">
        <CBadge color="success">{{ count }}</CBadge>
      </span>
    </CCardHeader>
    <CCardBody>
      <CDataTable class="mb-0 table-outline" hover :items="pendingProfilesTableItems" :fields="pendingProfilesTableFields"
                  @row-clicked="rowClickHandler" head-color="light" no-sorting>
        <td slot="name" slot-scope="{ item }">
          <Avatar v-if="item.type==='Company'"
                      :image="item.avatar"
                      :defaultImage="item.avatar"
                      :content="item.company"
                      :length="20"
              />
              <Avatar v-else
                      :image="item.avatar"
                      :defaultImage="item.avatar"
                      :content="item.name"
                      :length="20"
              /> 
          <!-- <span :title="item.name">{{ trimfunction(item.name, 15) }}</span> -->
        </td>
        
        <td slot="email" slot-scope="{ item }">
          <span :title="item.email">{{ trimfunction(item.email, 15) }}</span>
        </td>
        <td slot="description" slot-scope="{ item }">
          <span :title="item.description">{{ trimfunction(item.description, 15) }}</span>
        </td>
      </CDataTable>
      <CRow v-if="showProfile" style="margin-top: 2px">
        <CCol class="col-12 text-right">
          <button title="View All Profile" v-on:click="viewProfiles" class="btn btn-light btn-block mb-1">
            View All
          </button>
        </CCol>
      </CRow>
    </CCardBody>
    <CCardHeader>
      <strong>{{ this.$lang.dashboard.header.user_session_report }}</strong>
      <span style="float: right;">
        <CBadge color="success">{{ count }}</CBadge>
      </span>
    </CCardHeader>
    <CCardBody>
      <CDataTable class="mb-0 table-outline" hover :items="sessionTableItems" :fields="sessionTableFields"
                  @row-clicked="rowClickHandler" head-color="light" no-sorting>
        <td slot="name" slot-scope="{ item }">
          <span :title="item.name">{{ trimfunction(item.name, 15) }}</span>
        </td>
        <td slot="email" slot-scope="{ item }">
          <span :title="item.email">{{ trimfunction(item.email, 15) }}</span>
        </td>
        <td slot="description" slot-scope="{ item }">
          <span :title="item.description">{{ trimfunction(item.description, 15) }}</span>
        </td>
      </CDataTable>
      <CRow v-if="showProfile" style="margin-top: 2px">
        <CCol class="col-12 text-right">
          <button title="View All Profile" v-on:click="viewProfiles" class="btn btn-light btn-block mb-1">
            View All
          </button>
        </CCol>
      </CRow>
    </CCardBody>
    <CCardHeader>
      <strong>{{ this.$lang.dashboard.header.user_activity_report }}</strong>
      <span style="float: right;">
        <CBadge color="success">{{ count }}</CBadge>
      </span>
    </CCardHeader>
    <CCardBody>
      <CDataTable class="mb-0 table-outline" hover :items="activityTableItems" :fields="activityTableFields"
                  @row-clicked="rowClickHandler" head-color="light" no-sorting>
        <td slot="name" slot-scope="{ item }">
          <span :title="item.name">{{ trimfunction(item.name, 15) }}</span>
        </td>
        <td slot="email" slot-scope="{ item }">
          <span :title="item.email">{{ trimfunction(item.email, 15) }}</span>
        </td>
        <td slot="description" slot-scope="{ item }">
          <span :title="item.description">{{ trimfunction(item.description, 15) }}</span>
        </td>
      </CDataTable>
      <CRow v-if="showProfile" style="margin-top: 2px">
        <CCol class="col-12 text-right">
          <button title="View All Profile" v-on:click="viewProfiles" class="btn btn-light btn-block mb-1">
            View All
          </button>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import Vue from "vue";
import {ServerTable} from "vue-tables-2";
import store from "../../store/store";
import {Mixin} from "/src/mixins/index.js";
import {dashboardUserReport} from "../../store/url";
import Avatar from "/src/components/Avatar";


Vue.use(ServerTable, {}, false);
export default {
  name: "UserReport",
  mixins: [Mixin],
  components: {
    Avatar,
  },
  data() {
    return {
      self: this,
      count: 0,
      showProfile: false,
      module: dashboardUserReport,
      pendingProfilesTableItems: [],
      sessionTableItems: [],
      activityTableItems: [],
      sessionTableFields: [
        {key: "name", label: this.$lang.dashboard.userSession.name},
        {key: "countryCode", label: this.$lang.dashboard.userSession.countryCode},
        {key: "username", label: this.$lang.dashboard.userSession.username},
        {key: "time", label: this.$lang.dashboard.userSession.time},
      ],
      pendingProfilesTableFields: [
      {key: "type", label: this.$lang.dashboard.pendingProfiles.type},
        {key: "name", label: this.$lang.dashboard.pendingProfiles.name},
        {key: "createdAt", label: this.$lang.dashboard.pendingProfiles.time},
      ],
      activityTableFields: [
        {key: "name", label: this.$lang.dashboard.userActivity.name},
        {key: "username", label: this.$lang.dashboard.userActivity.username},
        {key: "time", label: this.$lang.dashboard.userActivity.time},
      ],
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    axios.get(this.module.api.listSessionUser)
      .then((response) => {
        self.sessionTableItems = response.data.data;
        self.count = response.data.count;
        if (response.data.data.length > 0) {
          self.showProfile = true;
        }
      });
      //pendingProfilesTableItems
      axios.get(this.module.api.pendingProfiles)
      .then((response) => {
        self.pendingProfilesTableItems = response.data.data;
        self.count = response.data.count;
        if (response.data.data.length > 0) {
          self.showProfile = true;
        }
      });
    axios.get(this.module.api.listActivityUser)
      .then((response) => {
        self.activityTableItems = response.data.data;
        self.count = response.data.count;
        if (response.data.data.length > 0) {
          self.showProfile = true;
        }
      });
  },
  methods: {
    rowClickHandler(item) {
      this.$router.push({path: `profile/view/${item.id}`});
    },
    viewProfiles() {
      this.$router.push({path: "profile"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
